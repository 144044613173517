import React from 'react';
import { motion } from 'framer-motion';
import './Apps.css';
import me from '../assets/me.png';

const Apps = () => {
  const containerRef = React.useRef(null);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const apps = [
    {
      title: 'Inventario',
      description: 'Application de gestion d\'inventaire',
      url: 'https://inventario.cyrillevarin.com'
    },
    {
      title: 'MyCrew',
      description: 'Gestion d\'équipe et de personnel',
      url: 'https://mycrew.cyrillevarin.com'
    }
  ];

  return (
    <section className="apps-container" ref={containerRef}>
      <motion.div
        className="profile-container"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="profile-image-wrapper">
          <motion.img
            src={me}
            alt="Cyrille Varin"
            className="profile-image"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          />
          <div className="beam-effect" />
        </div>
      </motion.div>

      <motion.div
        className="apps-grid"
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.2
            }
          }
        }}
      >
        {apps.map((app, index) => (
          <motion.a
            key={index}
            href={app.url}
            target="_blank"
            rel="noreferrer noopener"
            className="app-card"
            variants={cardVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h3>{app.title}</h3>
            <p>{app.description}</p>
            <div className="card-beam" />
          </motion.a>
        ))}
      </motion.div>
    </section>
  );
};

export default Apps;
