import React, { useRef, useState } from "react";
import "./contact.css"
import {MdOutlineEmail} from "react-icons/md"
import {PiWechatLogoBold} from "react-icons/pi"
import {BsWhatsapp} from "react-icons/bs"
import emailjs from "emailjs-com"
import {BsLinkedin} from "react-icons/bs"
import {FaGithub} from "react-icons/fa"
import {FaMedium} from "react-icons/fa"


const Contact = () => {
  const form = useRef();

  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_916vq45', 'template_i8z3xb4', form.current, 'edGUfEBhWUoEWr1x5')
      .then((result) => {
          console.log(result.text);
          setMessageSent(true);
      }, (error) => {
          console.log(error.text);
      }
      );

      e.target.reset()
  };

  if (messageSent) {
    setTimeout(() => {
      setMessageSent(false);
    }, 30000); // Le message disparaîtra
  }


  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>cyrille.varin@live.fr</h5>
            <a href="mailto:cyrille.varin@live.fr" target='_blank' rel="noreferrer noopener">Send a message</a>
          </article>

          <article className="contact__option">
            <PiWechatLogoBold className='contact__option-icon'/>
            <h4>WeChat</h4>
            <h5>Id: DjDc31</h5>
            <a href="https://u.wechat.com/kKe1AazTzkMf3x2nJnEZIxA" target='_blank' rel="noreferrer noopener">Send a message</a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+33 (0)671832913</h5>
            <a href="https://api.whatsapp.com/send?phone=+33671832913" target='_blank' rel="noreferrer noopener">Send a message</a>
          </article>

          <article className="contact__option">
            <a href="https://www.linkedin.com/in/cyrille-varin/" target='_blank' rel="noreferrer noopener" aria-label="Linkedin">
              <BsLinkedin />
            </a>
          </article>

          <article className="contact__option">
            <a href="https://github.com/DjDc31" target='_blank' rel="noreferrer noopener" aria-label="GitHub">
              <FaGithub />
            </a>
          </article>

          <article className="contact__option">
            <a href="https://cyrillevarin.medium.com" target='_blank' rel="noreferrer noopener" aria-label="Medium">
              <FaMedium />
            </a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="email" name="email" placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
          {messageSent && <p className="success-message">Votre message a été envoyé avec succès !</p>}
        </form>
      </div>
    </section>
  )
}

export default Contact
