import React, { useEffect, useState } from 'react';
import Elevator from '../../elevator';
import "./footer.css"
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import {FaMedium} from "react-icons/fa"
import {FaKaggle} from "react-icons/fa"
import {AiOutlineWechat} from "react-icons/ai"
import elevatorMusic from '../../assets/music/elevator.mp3';
import dingSound from '../../assets/music/ding.mp3';
import { Link } from 'react-router-dom'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (window.scrollY > 50) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition();

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  useEffect(() => {
    if (showButton) {
      new Elevator({
        element: document.querySelector('.elevator-button'),
        mainAudio: elevatorMusic,
        endAudio: dingSound
      });
    }
  }, [showButton]);

  return (
    <footer>
      <a href="/" className='footer__logo'>CyrilleVarin.Com</a>

      <ul className='permalinks'>
        <li><a href="/">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Organisations</a></li>
        <li><Link to="/apps">MyApps</Link></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/cyrille-varin/" target='_blank' rel="noreferrer noopener" aria-label="Linkedin">
          <BsLinkedin />
        </a>
        <a href="https://github.com/DjDc31" target='_blank' rel="noreferrer noopener" aria-label="GitHub">
          <FaGithub />
        </a>
        <a href="https://cyrillevarin.medium.com" target='_blank' rel="noreferrer noopener" aria-label="Medium">
          <FaMedium />
        </a>
        <a href="https://www.kaggle.com/varincy" target='_blank' rel="noreferrer noopener" aria-label="Kaggle">
          <FaKaggle />
        </a>
        <a href="https://u.wechat.com/kKe1AazTzkMf3x2nJnEZIxA" target='_blank' rel="noreferrer noopener" aria-label="WeChat">
          <AiOutlineWechat />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy; {currentYear} CyrilleVarin.Com All rights<br />
          <button onClick={() => window.openAxeptioCookies?.()} className="cookie-link">
            Click here to change your cookie preferences
          </button>
        </small>
      </div>
      {showButton && (
        <div className="elevator-button">
          <span className="elevator-text">Back to Top</span>
          <span className="elevator-icon">⬆</span>
        </div>
      )}
    </footer>
  )
}

export default Footer
